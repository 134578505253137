import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import AccountDetailModel from "../../models/Account/AccountDetailModel";
import Cookies from "js-cookie";
import { GlobalContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';



const AccountInformationPage = ( ) => {
    const [key, setKey] = useState<string | null>("1");
    const navigate = useNavigate();
    let {getJSON,sendData} = useContext(GlobalContext);
    const [formValidateAccount, setFormValidateAccount] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [accountDetail, setAccountDetail] = useState<AccountDetailModel>(new AccountDetailModel());
    const [error, setError] = useState(""); 
    const [file, setFile] = useState<File | undefined>();
    const [uploaded, setUploaded] =  useState(false);
    const [pricePlan, setPricePlan] = useState<string>('0.00');

    const today = new Date();
    const month = today.getMonth()+1;
    const year = today.getFullYear();
    const date = today. getDate();
    const currentDate = month.toString()  + date.toString()  + year.toString();
    const [serviceOptions,setServiceOptions] = useState<{value: string, label: string}[]>([]);
    const [categoryOptions,setCategoryOptions] = useState<{value: string, label: string}[]>([]);
    const [limit, setLimit] = useState<number>(0);

    const planData: {value: 'F' | 'CB' | 'CM' | 'CS' | 'CO' | 'CP', title: string, price: string, plan_specs: string}[] = [
        {
            value: 'F',
            title: 'Plan Gratuito',
            price: '0.00',
            plan_specs: "<div> Descuento 5% Sem, 10% anual <br>1 Imagen <br/>1 Espacio adicional en segmentos de area <br/></div>"
        },
        {
            value: 'CB',
            title: 'Combo Básico',
            price: '100.00',
            plan_specs: "<div> Descuento 5% Sem, 10% anual <br/>3 Imágenes <br/>Cambia 1 imagen por mes (Solo contrato anual)<br/>1 Espacio adicional en segmentos de area <br/> </div>"
        },
        {
            value: 'CM',
            title: 'Combo Medio',
            price: '200.00',
            plan_specs: "<div>Descuento 5% Sem, 10% anual <br/>3 Imágenes y 2 Videos 10seg c/u <br/>Cambia 1 imagen por mes (Solo contrato anual<br/>1 Sugerencia por mes a posible consumidor<br/>2 Espacios adicionales en segmentos de area o categorías <br/> 10 visualizaciones en slider <br/></div>"
        },
        {
            value: 'CS',
            title: 'Combo Silver',
            price: '300.00',
            plan_specs: " Descuento 5% Sem, 10% anual <br/>3 Imágenes y 2 Videos 10seg c/u <br/>Cambia 1 imagen por mes (Solo contrato anual)<br/>1 Sugerencia por mes a posible consumidor<br/>2 Espacios adicionales en segmentos de area o categorías <br/> 10 visualizaciones en slider <br/>"
        },
        {
            value: 'CO',
            title: 'Combo Oro',
            price: '500.00',
            plan_specs: "<div>Descuento 5% Sem, 10% anual <br/> 3 Imágenes y 3 videos 10 seg c/u <br/>Cambia 1 imagen y 1 video por mes  (Solo contrato anual)<br/>2 Sugerencias por mes a posible consumidor<br/>2 Espacios adicionales en segmentos de area o categorías <br/> 20 visualizaciones en slider de segmento de área<br/> 1 visualización en slider de página de inicio</div>"
        },
        {
            value: 'CP',
            title: 'Combo Platino',
            price: '1000.00',
            plan_specs: "<div>Descuento 5% Sem, 10% anual <br/>3 Imágenes y 3 videos 10 seg c/u <br/>Cambia 1 imagen y 2 video por mes  (Solo contrato anual)<br/>3 Sugerencias por mes a posible consumidor<br/>3 Espacios adicionales en segmentos de area o categorías <br/> 20 visualizaciones en slider de segmento de área<br/> 1 visualización en slider de página de inicio</div>"
        }
    ];

    const getOptions = () =>{

        getJSON("provider", "GetServicesFront", []).then((response : any) => {
            if(response.status){
                const dataService: {value: string, label: string}[] = [];
                response.data.forEach((service:any) => {
                    dataService.push({value: service.id, label: service.name});
                });
                setServiceOptions(dataService);
            }
        });

        getJSON("provider", "GetCategoriesFront", []).then((response : any) => {
            if(response.status){
                const dataCategories: {value: string, label: string}[] = [];
                response.data.forEach((category:any) => {
                    dataCategories.push({value: category.id, label: category.name});
                });
                setCategoryOptions(dataCategories);
            }
        });
    }

    const onChangeCategories = (e:any) => {
        const categories: number[] = [];
        e.forEach((item:{value: number, label: string}) => {
            categories.push(item.value);
        });
        setAccountDetail({
            ...accountDetail,
            categories: categories
        });
    }
    const onChangeServices = (e:any) => {
        const services: number[] = [];
        e.forEach((item:{value: number, label: string}) => {
            services.push(item.value);
        });
        setAccountDetail({
            ...accountDetail,
            services: services
        });
    }

    const validateForm = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
    
        if(form.checkValidity()) {
           sendAccountData();
        }

        setFormValidateAccount(true);
    }

    const getDataAccount = () => {
        let params = {
            "token_web": Cookies.get("token")
        }

        getJSON("provider", "accountDetail", params).then((response : any) => {
            if(response.status){
                setAccountDetail(response.data);
            }
        });
    }

    const sendAccountData = ( ) => {
        setLoading(true);
        let data = { 
            token_web: Cookies.get("token"),
            detail: accountDetail
        }
        getJSON("provider", "updateData", data).then((response : any) => {
            if(response.status){
                let current = parseInt(key!!);
                let newvalue =  current + 1;
                setKey(newvalue.toString());
            }else{
                navigate("/login")
            }
           
        });
        setLoading(false);
    }

    const toAccount = () => {
        navigate("/account");
    } 

    const handleOnChangeFile =  (e: React.FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement & {
          files: FileList;
        }


        
        setFile(target.files[0]);
    }

    const submitFile =  () => {
        if ( typeof file === 'undefined' ) return;

        const formData: FormData = new FormData(undefined);
        const token = Cookies.get("token");
        const idProvider = accountDetail.id.toString();

        formData.append('file', file);
        formData.append('idProvider', idProvider);
        formData.append('token_web', token!!.toString());
        formData.append('plan', accountDetail.selectedPlan);

        sendData("provider", "uploadFile", formData, undefined, "formData").then((response : any) => {
            if(response.status){
               console.log(response);
               setUploaded(true);
            }else{
                console.log(response);
            }
           
        });
    }

    useEffect( () => {
        if(serviceOptions.length == 0 && categoryOptions.length == 0 && limit < 5){
            getOptions();
        }

        if(accountDetail.id === 0 && limit < 5){
            getDataAccount();
        }

    })

    return(
        <Container>
             <Tabs
                defaultActiveKey="profile"
                id="fill-tab-example"
                className="mb-3"
                activeKey={key!!}
                onSelect={(k) => setKey(k)}
                fill
                >
                <Tab eventKey="1" title="Información">
                    <Form noValidate validated={formValidateAccount} onSubmit={validateForm}>
                            <Row md={1} xs={1} lg={1}>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalName">
                                        <Row>
                                            <Col md={3} className="label-hf" style={{textAlign: 'right'}}>
                                                <Form.Label>Nombre</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="text"
                                                    className="shadowInput"
                                                    value={accountDetail.name} 
                                                    disabled
                                                    />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalName">
                                        <Row>
                                            <Col md={3} className="label-hf" style={{textAlign: 'right'}}>
                                                <Form.Label>Nombre del Contacto*</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="text"
                                                    className="shadowInput"
                                                    placeholder="Nombre..."
                                                    value={accountDetail.contact_name} 
                                                    onChange={ (event: any) => {
                                                        setAccountDetail({
                                                            ...accountDetail,
                                                            contact_name: event.target.value
                                                        });
                                                        setFormValidateAccount(false);
                                                    }} 
                                                    required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor, ingresa el nombre del contacto.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalName">
                                        <Row>
                                            <Col md={3} className="label-hf" style={{textAlign: 'right'}}>
                                                <Form.Label>Email del Contacto*</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="text"
                                                    className="shadowInput"
                                                    value={accountDetail.username} 
                                                    disabled/>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalName">
                                        <Row>
                                            <Col md={3} className="label-hf" style={{textAlign: 'right'}}>
                                                <Form.Label>RFC*</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="text"
                                                    className="shadowInput"
                                                    maxLength={13}
                                                    placeholder="RFC..."
                                                    value={accountDetail.rfc} 
                                                    onChange={ (event: any) => {
                                                        setAccountDetail({
                                                            ...accountDetail,
                                                            rfc: event.target.value
                                                        });
                                                        setFormValidateAccount(false);
                                                    }} 
                                                    required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor, ingresa tu RFC.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalName">
                                        <Row>
                                            <Col md={3} className="label-hf" style={{textAlign: 'right'}}>
                                                <Form.Label>CURP*</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="text"
                                                    className="shadowInput"
                                                    maxLength={18}
                                                    placeholder="CURP..."
                                                    value={accountDetail.curp} 
                                                    onChange={ (event: any) => {
                                                        setAccountDetail({
                                                            ...accountDetail,
                                                            curp: event.target.value
                                                        });
                                                        setFormValidateAccount(false);
                                                    }} 
                                                    required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor, ingresa tu CURP.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalName">
                                        <Row>
                                            <Col md={3} className="label-hf" style={{textAlign: 'right'}}>
                                                <Form.Label>Nombre del segundo contacto*</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="text"
                                                    className="shadowInput"
                                                    value={accountDetail.second_contact_name} 
                                                    onChange={ (event: any) => {
                                                        setAccountDetail({
                                                            ...accountDetail,
                                                            second_contact_name: event.target.value
                                                        });
                                                        setFormValidateAccount(false);
                                                    }} 
                                                    required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor, ingresa el nombre del contacto.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalName">
                                        <Row>
                                            <Col md={3} className="label-hf" style={{textAlign: 'right'}}>
                                                <Form.Label>Email del segundo contacto*</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="email"
                                                    className="shadowInput"
                                                    value={accountDetail.second_contact_email} 
                                                    onChange={ (event: any) => {
                                                        setAccountDetail({
                                                            ...accountDetail,
                                                            second_contact_email: event.target.value
                                                        });
                                                        setFormValidateAccount(false);
                                                    }} 
                                                    required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor, ingresa el email del contacto.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalName">
                                        <Row>
                                            <Col md={3} className="label-hf" style={{textAlign: 'right'}}>
                                                <Form.Label>Nombre del tercer contacto</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="text"
                                                    className="shadowInput"
                                                    value={accountDetail.third_contact_name} 
                                                    onChange={ (event: any) => {
                                                        setAccountDetail({
                                                            ...accountDetail,
                                                            third_contact_name: event.target.value
                                                        });
                                                        setFormValidateAccount(false);
                                                    }} 
                                                    />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formHorizontalName">
                                        <Row>
                                            <Col md={3} className="label-hf" style={{textAlign: 'right'}}>
                                                <Form.Label>Email del tercer contacto</Form.Label>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control 
                                                    type="email"
                                                    className="shadowInput"
                                                    value={accountDetail.third_contact_email} 
                                                    onChange={ (event: any) => {
                                                        setAccountDetail({
                                                            ...accountDetail,
                                                            third_contact_email: event.target.value
                                                        });
                                                        setFormValidateAccount(false);
                                                    }} 
                                                    />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Row md={2}>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formHorizontalName">
                                                <Row>
                                                    <Col md={12} className="label-hf" style={{textAlign: 'left'}}>
                                                        <Form.Label>Servicios</Form.Label>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Select
                                                            isMulti
                                                            name="services"
                                                            onChange={onChangeServices}
                                                            options={serviceOptions}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formHorizontalName">
                                                <Row>
                                                    <Col md={12} className="label-hf" style={{textAlign: 'left'}}>
                                                        <Form.Label>Categorias</Form.Label>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Select
                                                            isMulti
                                                            name="categories"
                                                            onChange={onChangeCategories}
                                                            options={categoryOptions}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                
                                <Row md={2} xs={1}>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formHorizontalName">
                                            <Row>
                                                <Col md={3} className="label-hf" style={{textAlign: 'right'}}>
                                                    <Form.Label>Precios Desde:</Form.Label>
                                                </Col>
                                                <Col md={9}>
                                                    <Form.Control 
                                                        type="number"
                                                        className="shadowInput"
                                                        value={accountDetail.min_amount}
                                                        required
                                                        onChange={ (event: any) => {
                                                            setAccountDetail({
                                                                ...accountDetail,
                                                                min_amount: event.target.value
                                                            });
                                                            setFormValidateAccount(false);
                                                        }} 
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formHorizontalName">
                                            <Row>
                                                <Col md={3} className="label-hf" style={{textAlign: 'right'}}>
                                                    <Form.Label>Precios Hasta:</Form.Label>
                                                </Col>
                                                <Col md={9}>
                                                    <Form.Control 
                                                        type="number"
                                                        className="shadowInput"
                                                        value={accountDetail.max_amount}
                                                        required
                                                        onChange={ (event: any) => {
                                                            setAccountDetail({
                                                                ...accountDetail,
                                                                max_amount: event.target.value
                                                            });
                                                            setFormValidateAccount(false);
                                                        }} 
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            
                                <div style={{color: 'red', textAlign: 'center',fontSize: '14px', display: error !== '' ? 'block' : 'none'}}>{error}</div>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <p style={{textAlign: 'right'}}> *Datos obligatorios </p>
                                </Col>
                            </Row>
                            <Row>
                                
                                <Col md={{offset: 9, span: 3}} style={{textAlign: 'center',display: "flex", justifyContent: "end"}}>
                                    <Button disabled={isLoading} style={{backgroundColor: 'rgb(241, 46, 149)'}} type="submit">
                                    <Spinner
                                        className={ isLoading ? 'spinner-active' : 'spinner-not-active' }
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        />
                                    {isLoading ? 'Enviando...' :  'Enviar'}
                                    </Button>
                                </Col>
                                
                            </Row>
                    </Form>
                </Tab>
                <Tab disabled={parseInt(key!!) <= 2 } eventKey="2" title="Selecciona tu plan">
                   <Row md={3} lg={6} xs={2}>
                        {planData.map((plan: any , index: number) => {
                            return(
                                <Col key={index + 'card-plan'} className={`card-plan ${plan.value == accountDetail.selectedPlan ? 'selected' : ''}`}>
                                <span className="plan-title"> { plan.title +  `(${plan.value})` }</span>
                                <span className="plan-price"> $ {plan.price} MXN / Mes </span>
                                <div className="plan-especs" dangerouslySetInnerHTML={{__html: plan.plan_specs }}>
                                   
                                </div>
                                <span>
                                    <input type="radio" name="plan" value={plan.value} onChange={(e:any) => {
                                        setAccountDetail({
                                            ...accountDetail,
                                            selectedPlan: e.target.value
                                        });
                                        setPricePlan(plan.price);
                                    }} />
                                </span>
                            </Col>
                            )
                        })}
                   </Row>
                   <Row>
                        <div className={`progressSteps`}>
                            <Button onClick={() => {
                                setKey("1");
                            }}>
                                Anterior
                            </Button>
                            <Button onClick={() => sendAccountData()}>
                                Siguiente
                            </Button>
                        </div>
                    </Row>
                </Tab>
                <Tab disabled={parseInt(key!!) <= 3} eventKey="3" title="Datos Bancarios">
                    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', maxWidth: '600px', margin: 'auto', border: '1px solid #ddd', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                        <h2 style={{ textAlign: 'center' }}>Método de Pago para Suscripción</h2>
                        <p style={{ textAlign: 'center' }}>Por favor, realiza la transferencia interbancaria a la siguiente cuenta:</p>
                        <div style={{ textAlign: 'center', margin: '20px 0' }}>
                            <img src="https://via.placeholder.com/50" alt="Transferencia Bancaria" style={{ verticalAlign: 'middle', marginBottom: '10px' }} />
                            <h3>Datos de la Cuenta</h3>
                            <p><strong>Banco:</strong> Banco Ejemplo</p>
                            <p><strong>CLABE:</strong> 123456789012345678</p>
                            <p><strong>Número de Cuenta:</strong> 1234567890</p>
                            <p><strong>Referencia / Concepto:</strong>{accountDetail.id + "_" +  currentDate + "_homefiesta"}</p>
                            <p style={{fontSize: '1.3em'}}><strong>Total a pagar:</strong> ${pricePlan} MXN</p>
                        </div>
                        <p style={{ textAlign: 'center' }}>Para más información o asistencia, contáctanos en <a href="mailto:support@example.com">contacto@vivalasfiestas.com</a>.</p>
                    </div>
                    <Row>
                        <div className={`progressSteps`}>
                            <Button onClick={() => {
                                setKey("2");

                            }}>
                                Anterior
                            </Button>
                            <Button onClick={() => {
                                setKey("4");

                            }}>
                                Siguiente
                            </Button>
                           
                        </div>
                    </Row>
                </Tab>
                <Tab disabled={key !== '4'} eventKey="4" title="Sube tu comprobante">
                    <Row md={1} className="uploadFile">
                        <Col>
                            <span>Sube tu comprobante a continuación </span>
                        </Col>
                        <Form>
                            <input
                                id="image"
                                type="file"
                                name="image"
                                accept="image/png, image/jpg, application/pdf"
                                onChange={handleOnChangeFile}
                            />
                           <Button onClick={submitFile} disabled={isLoading} style={{backgroundColor: 'rgb(241, 46, 149)', maxWidth: '200px'}} >
                            <Spinner
                                className={ isLoading ? 'spinner-active' : 'spinner-not-active' }
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                            {isLoading ? 'Enviando...' :  'Enviar'}
                            </Button>
                        </Form>
                        <div style={{color: 'green', textAlign: 'center',fontSize: '14px', display: uploaded  ? 'block' : 'none'}}> Se ha subido tu documento correctamente, ahora esta en proceso de revisión y te notificaremos cuando haya sido revisado</div>
                    </Row>
                    <Row>
                        <div className={`progressSteps`}>
                            <Button onClick={() => {
                                
                                setKey("3");

                            }} >
                                Anterior
                            </Button>
                            
                            <Button disabled={!uploaded} onClick={() => toAccount()} className={`progressButtons ${key === "4" ? '' : 'notshow' }`}>
                                Finalizar
                            </Button>
                        </div>
                    </Row>
                </Tab>
            </Tabs>
           
        </Container>
    )
}

export default AccountInformationPage;