import { Col, Container, Row,Image, Form, Button } from "react-bootstrap"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation } from "swiper";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faEnvelope, faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import ProviderModel from "../../models/ProviderModel";
import { GlobalContext } from "../../context/GlobalContext";
import { MenuItemsModel } from "../../models/Home/MenuItemsModel";
import GoogleMapReact, { latLng2Tile } from "google-map-react";
import CoordinateCheck from "../../component/KMRadioChecker/KmRadioCheckerComponent";

const AnyReactComponent = (text: any ) => <div><FontAwesomeIcon  className="marker" size='3x' icon={faMapMarkerAlt} /><p className="text-marker">{text.text}</p></div>;

export const HomePage = () =>{
    const [events,setEvents] = React.useState<any[]>([]);
    const [location, setLocation] = React.useState<{lat:string,lng:string}>({lat: '19.42847',lng: '-99.12766'});
    const [providers,setProviders] = React.useState<ProviderModel[]>([]);
    const [categories,setCategories] = React.useState<any[]>([]);
    const [mapZoom, setMapZoom] = React.useState<number>(13);
    let {getJSON} = useContext(GlobalContext);

    const mapRef = React.createRef();
    
    const successLocation = (position:any) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setLocation({ lat: latitude, lng: longitude });
    }
    
    const errorLocation = () => {
        console.log("Unable to retrieve your location");
    }
    
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(successLocation, errorLocation);
        } else {
            console.log("Geolocation not supported");
        }
    }

    const getItemsMenu = () => {
        let params = {

        }
        getJSON("category", "getAll", params).then((response: any) => {
            if(response.status){
                setEvents(response.data);
            }
        });
    }

    const getProviders = async () =>{
        let params = {

        }
        await  getJSON("provider", "getAllWithImages", params).then((response: any) => {
            if(response.status){
                setProviders(response.data);
            }
        });
    }

    const getCategories = async () => {
        let params = {

        }
        await  getJSON("category", "getAllByFeatured", params).then((response: any) => {
            if(response.status){
                setCategories(response.data);
            }

        });
    }

    const changeSelectAddress = (e:any) => {
        const value = e.target.value;

        switch (value) {
            case 'azcapo':
                setLocation({lat: '19.48698',lng: '-99.18594'})
                break;
            case 'iztapa':
                setLocation({lat: '19.35529',lng: '-99.06224'})
                break;
            case 'chimal':
                setLocation({lat: '19.42155',lng: '-98.95038'})
                break;
            case 'tlalpan':
                setLocation({lat: '19.29513',lng: '-99.16206'})
                break;
            case 'bj':
                setLocation({lat: '19.3984',lng: '-99.15766'})
                break;
            case 'my':
                getLocation();
                break;
            default:
                break;
        }

        setMapZoom(12);
        
        
    }

    useEffect(() => {
        if(events.length == 0){
            getItemsMenu();
        }
    },[])

    useEffect(() => {
        if(providers.length == 0){
            getProviders();
            getCategories();
        }
        //getLocation();
    },[null]);

    useEffect(() => {
        document.title = "Home Fiesta - Inicio";
    })

    // useEffect(() => {
    //     const element = [];
    //     for (let index = 0; index < 39; index++) {
    //         element.push({name: 'Evento' + (index + 1),url: '/url?' + index});
    //     } 
    //     setEvents(element);
    // },[null])
    const homeStyles = {
        boxed:{
            maxWidth: '75vw',
        },
        rowText:{
            paddingTop: '1.3em',
            color: '#F14E95',
            fontSize: '1.5em'
        },
        rowText2:{
            paddingTop: '1.3em',
            color: '#F12E95',
            fontSize: '2.2em',
            fontWeight: 700,
            textAling: 'center'
        }
    }

    return(
        <Container className="boxed">
            <Row>
                <Col lg={12}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation={true}
                        className="swiper-home"
                        modules={[Navigation]}

                        >
                          {providers.map((provider:ProviderModel , index: number) => {
                            {if(provider.in_home){
                                return <SwiperSlide key={index} className="swiper-content-provider">
                                    <Image src={provider.url} alt={provider.name}/>
                                </SwiperSlide>
                            }}
                        })}
                    </Swiper>
                </Col>
            </Row>
            <Row style={homeStyles.rowText}>
                <Col>Inicio</Col>
            </Row>
            <Row style={homeStyles.rowText2}>
                <Col lg={12} style={{textAlign: 'center'}}>Categorias Destacadas <br/></Col>
                <Col lg={12} className="swiper-providers-container">
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={6}
                        navigation={true}
                        breakpoints={{
                            400:{
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 6,
                                spaceBetween: 20,
                            },
                        }}
                        className="swiper-home-providers"
                        modules={[Navigation]}
                        >
                           {categories.map((category:any, index:number) => {
                            const url = category.image_url === null ? 'https://api.vivalasfiestas.com/modules/admin/categoria/default_category.png' : category.image_url;
                                return <SwiperSlide key={index} className="swiper-content-providers">
                                <Link to={"/events/" + category.id }>
                                    <Image src={url} alt={category.name} />
                                    <span className="title" style={{justifyContent: 'center', textAlign: 'center'}}>{category.name}</span>
                                    
                                </Link>
                            </SwiperSlide>
                            })}
                    </Swiper>
                </Col>
                <Col lg={12} style={{paddingTop: '2.3em', paddingBottom: '1.5em',fontWeight: 400, textAlign: 'center'}}>Todo lo que buscas</Col>
            </Row>
            <Row>
                <Col lg={12}>
                <ul className="list-events">
                    {events.map((item:any, index:number) => {
                        return<li className="list-item-event" key={index}> <Link to={"/events/" + item.id} >
                            {item.name}
                        </Link></li>
                    })}
                </ul>
                
                </Col>
            </Row>
            {/* <Row>
                <Col lg={3}>
                    <p className="text-mejora">Mejora tu Fiesta</p>
                    <ul className="list-events-2">
                        <li className="list-item-event"><Link to={"/events?pruebas"}>Tips</Link></li>
                        <li className="list-item-event"><Link to={"/events?pruebas"}>Recomendaciones</Link></li>
                        <li className="list-item-event"><Link to={"/events?pruebas"}>Etiqueta</Link></li>
                        <li className="list-item-event"><Link to={"/events?pruebas"}>Vestuario</Link></li>
                        <li className="list-item-event"><Link to={"/events?pruebas"}>Regalar</Link></li>
                        <li className="list-item-event"><Link to={"/events?pruebas"}>Que no debe faltar</Link></li>
                        <li className="list-item-event"><Link to={"/events?pruebas"}>Promociones</Link></li>
                    </ul>
                </Col>
            </Row> */}

            <Row className="ubica">
                <Col lg={12}>
                    <p className="text-ubica">Ubica Proveedores en tu Zona</p>
                    <select onChange={changeSelectAddress} name="delegacion" id="delegacion-select">
                        <option value="">--Selecciona--</option>
                        <option value="azcapo">(Ciudad de México) Azcapotzalco</option>
                        <option value="iztapa">(Ciudad de México) Iztapalapa</option>
                        <option value="chimal">(Estado de México) Chimalhuacán</option>
                        <option value="tlalpan">(Ciudad de México) Tlalpan</option>
                        <option value="bj">(Ciudad de México) Benito Juárez</option>
                        <option value="my">Utiliza mi ubicación </option>
                    </select>
                </Col>
                <Col lg={12}>
                    <div style={{position: "relative"}}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyBJywWDNp2KQiWH6yoUbDR4VX0OFHk_24I"}}
                            center={{lat: parseFloat(location.lat), lng: parseFloat(location.lng) }}
                            zoom={mapZoom}
                            
                            onGoogleApiLoaded={({ map, maps }) => {
                                /**map.fitToElements({
                                    edgePadding: {
                                        bottom: 50, right: 50, left: 50, top: 50
                                    }, 
                                    animated: true 
                                });**/
                            }}
                            yesIWantToUseGoogleMapApiInternals
                            style= {{height: "450px", width: "100%", display: "block"}}
                            draggable={true}
                        >
                            {providers.map((provider: any, index) => {
                                const coordinatesProv = provider.coordinates.split(',');
                                const coordinatesReferer: {lat: string , lng: string} = {lat: coordinatesProv[0], lng: coordinatesProv[1]}
                                    const name = provider.name;
                                    if(CoordinateCheck(coordinatesReferer, location , 10)){
                                        return <AnyReactComponent
                                            key={index}
                                            lat={parseFloat(coordinatesProv[0])}
                                            lng={parseFloat(coordinatesProv[1])}
                                            className="marker"
                                            draggable={true}
                                            text={name}
                                        />
                                    }else{
                                        return <></>
                                    }
                                    
                                
                            })} 

                            <AnyReactComponent
                                lat={parseFloat(location.lat)}
                                lng={parseFloat(location.lng)}
                                className="marker"
                                draggable={true}
                                text={'Tu estás aquí'}
                            />

                        </GoogleMapReact>
                    </div>
                </Col>
            </Row>

            <Row className="contacto">
                <Col lg={12}><p className="title">Contacto</p><p className="siguenos">Síguenos</p></Col>
                <Col lg={{span: 5, offset: 1}}>
                    <p className="title-info">Información de Contacto</p>
                    <p className="info-contact">
                        <a href="tel:5515011770">
                            <FontAwesomeIcon icon={faPhone} /> &nbsp;&nbsp;55-1501-1770
                        </a>
                        <a href="mailto:thehomefiesta@hotmail.com">
                            <FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;thehomefiesta@hotmail.com
                        </a>
                    </p>
                </Col>
                <Col lg={6}>
                    <p className="title-info">Escríbenos</p>
                    <Form>
                        <Row lg={2} xs={1}>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control required type="text" placeholder="Tu Nombre" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Apellido</Form.Label>
                                    <Form.Control required type="text" placeholder="Tu Apellido" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row lg={1} xs={1}>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control required type="email" placeholder="Tu Correo Electrónico" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Asunto</Form.Label>
                                    <Form.Control required type="text" placeholder="Asunto" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Mensaje</Form.Label>
                                    <Form.Control required as="textarea" rows={3} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="button-form">
                            <Button className="send-button" formAction="submit">Enviar</Button>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}